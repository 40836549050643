<template>
  
    <div class="reset">
      <div class="left_side" :style="onload ? 'left: 70px; opacity: .7;' : null">

        <form @submit.prevent="signInClicked" class="form">
          <h2>Enter your new password</h2>
          <p>Your new password must be different than previous passwords.  A strong password includes more than 8 characters, a special character, a number, and a case change.</p>

          <p class="err_message" v-if="err_pass" v-text="errMsg"></p>
          
          <label>
            <div class="caps_caution" v-if="caps">Caps lock is on</div>

            <input :type="revealOne ? 'text' : 'password'" :class="{ 'err_pass': err_pass }" @keypress="err_pass = false" placeholder="Enter new password" v-model="newPasswordOne" @paste="onPaste">
            <span class="eye" @click="revealOne = !revealOne">
              <span v-if="revealOne"></span>
            </span>
				    <stages class="stages" :complexity="complexity"></stages>
          </label>
          
          <label class="confirm_pass">
            <input :type="revealTwo ? 'text' : 'password'" :class="{ 'err_pass': err_pass }" @keypress="err_pass = false" placeholder="Confirm new password" v-model="newPasswordTwo" @paste="onPaste">
            <span class="eye" @click="revealTwo = !revealTwo">
              <span v-if="revealTwo"></span>
            </span>
          </label>

          <div class="button_group">
            <button class="submit_reset back_to_reset" @click.stop="router.push('/login')">Back to sign in</button>
            <button class="submit_reset" @click="newPasswordEntered()">Reset</button>
          </div>
        </form>

      </div>

      <div class="right_side" :style="onload ? 'right: 70px; opacity: .7;' : null">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
    </div>
  
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword";
import stages from '../components/password/password.vue';
import { useRouter } from 'vue-router';
const router = useRouter();

let complexity = ref(0);
let onload = ref(true);
let err_pass = ref(false);
let newPasswordOne = ref('');
let newPasswordTwo = ref('');
let caps = ref(false);
let errMsg = ref('Wrong Password');
let revealOne = ref(false);
let revealTwo = ref(false);

document.addEventListener('keyup', (e) => {
  if (e.getModifierState('CapsLock')) {
    caps.value = true;
  } else {
    caps.value = false;
  }
});
function onPaste(e) {
	e.preventDefault();
	let pastedText = (e.clipboardData || window.clipboardData).getData('text');
	pastedText = pastedText.split(' ').join('');
	e.clipboardData.setData('text/plain', pastedText);
	document.execCommand("insertHTML", false, pastedText);
};
function watchPass() {
	let hasNumber = /\d/;
	let uppercase = /[A-Z]/;
	let character = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

	if (newPasswordOne.value.length >= 9 && hasNumber.test(newPasswordOne.value) && uppercase.test(newPasswordOne.value) && character.test(newPasswordOne.value)) {
		complexity.value = 4;
	} else if(newPasswordOne.value.length >= 8 && hasNumber.test(newPasswordOne.value) && uppercase.test(newPasswordOne.value) || newPasswordOne.value.length >= 8 && hasNumber.test(newPasswordOne.value) && character.test(newPasswordOne.value) || newPasswordOne.value.length >= 8 && uppercase.test(newPasswordOne.value) && character.test(newPasswordOne.value)) {
		complexity.value = 3;
	} else if(newPasswordOne.value.length >= 8) {
		complexity.value = 2;
	} else if(newPasswordOne.value.length >= 1 && newPasswordOne.value.length <= 8 && !uppercase.test(newPasswordOne.value) && !character.test(newPasswordOne.value)) {
		complexity.value = 1;
	} else if(newPasswordOne.value == '') {
		complexity.value = 0;
  }
};

watch(newPasswordOne, () =>
	watchPass(), {
	immediate: true
});

async function newPasswordEntered() {
  // console.log(newPassword.value,'newPassword')
  if (complexity.value <= 1) {
    err_pass.value = true;
    errMsg.value = 'Password is too weak'
  } else if (newPasswordOne.value !== newPasswordTwo.value) {
    err_pass.value = true;
    errMsg.value = "Passwords don't match"
  } else { 
    try {
      let response = await submitNewPassword({
        formFields: [{
          id: "password",
          value: newPasswordOne.value
        }]
      });
      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach(formField => {
          if (formField.id === "password") {
            // New password did not meet password criteria on the backend.
            err_pass.value = true
            errMsg.value = formField.error
          }
        })
      } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
        errMsg.value = "Password reset failed. Please try again";
      } else {
        errMsg.value = "Password reset successful!";
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        console.log("dude! " + err.message);
      } else {
        console.log("Oops! Something went wrong.");
      }
    }
  }
}

onMounted(() => {
  setTimeout(() => onload.value = false, 10);
});    
</script>

<style lang="scss" scoped>
.reset {
  background-color: #f8f8f8;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .left_side, .right_side {
    transition: all 0.3s ease;
  }
  .left_side {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #f2f2f2;
    .form {
      overflow: hidden;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -220px;
      width: 400px;
      text-align: center;
      background-image: url(../../public/img/bopus/bopus.svg);
      background-size: 200px;
      background-position: center top;
      background-repeat: no-repeat;
      padding-top: 100px;
      transform: translate(-50%, -100px);
      .button_group{
        display: flex;
        justify-content: space-between;
        margin-top: 46px;
        .back_to_reset{
          margin-right: 16px;
          background-color: transparent;
          color: #787878;
          border: 1px solid #787878;
          cursor: pointer;
        }
        .back_to_reset:hover{
          color: #006C3E;
          border: 1px solid #006C3E;
        }
      }
      .err_login, .err_pass {
        border: 1px solid #CD180A;
      }
      .err_message {
        margin: 0;
        font-size: 0.82rem;
        text-align: left;
        line-height: 16px;
        margin-top: -10px;
        margin-bottom: 10px;
        color: #CD180A;
        text-indent: 0px;
      }
      label {
        position: relative;
        margin-top: 5px;
        margin-bottom: 16px;
        display: block;
        left: 0;
        .caps_caution{
          position: absolute;
          color: #1467d0;
          font-size: .75rem;
          line-height: .82rem;
          top: -1rem;
          font-weight: 500;
          right: 0;
        }
        .caps_caution::before{
          display: inline-block;
          content: 'i';
          color: #fff;
          background-color: #1467d0;
          height: .8rem;
          width: .8rem;
          line-height: .9rem;
          font-size: .6rem;
          border-radius: 50%;
          font-style: italic;
          position: absolute;
          left: -.9rem;
          top: -1px;
          text-align: center;
          text-indent: -2px;
        }
      }
      .confirm_pass{
        margin-top: 32px;
      }
      .eye {
        display: inline-block;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        top: 10px;
        background-image: url(../../public/img/password.png);
        background-position: center;
        background-repeat: no-repeat;
				cursor: pointer;
        span {
          display: inline-block;
          height: 27px;
          width: 2px;
          background-color: #787878;
          transform: rotate(60deg);
          position: relative;
          top: 7px;
          right: 0px;
        }
      }
      >p {
        color: #787878;
        font-size: 1rem;
        line-height: 1.2rem;
        margin-bottom: 40px;
      }
      h2 {
        font-size: 1.53rem;
        line-height: 1.8rem;
        height: 40px;
        margin-bottom: 16px;
      }
      input, button {
        max-width: 400px;
        box-sizing: border-box;
        border: none;
        outline: none;
        border-radius: 8px;
        transition: all .3s;
      }
      button:hover {
        background-color: #f2f8f5;
        color: #006C3E;
        border: 1px solid #006C3E;
      }
      input {
        width: 100%;
        border: 1px solid #fff;
        box-sizing: border-box;
        height: 60px;
        line-height: 60px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.02);
        text-indent: 16px;
        color: #787878;
        font-size: 1rem;
        position: relative;
      }
      input::placeholder {
        color: #787878;
        font-size: 1rem;
        font-family: 'Inter', sans-serif;
      }
      button {
        width: 100%;
        height: 60px;
        background-color: #006C3E;
        font-weight: 500;
        font-size: 1rem;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .right_side {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-color: #006C3E;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../public/img/w7login.png);
    .top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 40%;
      transform: rotate(180deg);
    }
    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
    }
    .top, .bottom {
      background-image: url(../../public/img/vectors.png);
      background-position: bottom;
      background-repeat: repeat-x;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px) and (min-height: 961px) and (orientation: portrait) {
  .reset {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: top;
      background-repeat: repeat-x;
      width: 100%;
      background-size: 540px;
      .form {
        background-image: url(../../public/img/bopus/bopus.svg);
        background-size: 180px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 100px;
        top: 45%;
        left: 50%;
        transform: translate(-50%,-10%);
      }
    }
    .right_side {
      display: none;
    }
  }
}

@media screen and (max-width: 599px) and (orientation: portrait) {
  .reset {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: center -60px;
      background-repeat: repeat-x;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      .form {
        background-image: url(../../public/img/bopus/bopus.svg);
        background-size: 120px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 60px;
        width: inherit;
        box-sizing: border-box;
        position: relative;
        margin-top: 200px;
        text-align: center;
        top: auto;
        left: auto;
        margin-left: auto;
        transform: translateX(0%);

        > p {
          color: #787878;
          font-size: 1rem;
          line-height: 1.2rem;
          margin-bottom: 25px;
          margin-top: 8px;
        }

        h2 {
          font-size: 1.53rem;
          line-height: 1.8rem;
          height: 32px;
          margin-bottom: 18px;
        }
      }
    }
    .right_side {
      display: none;
    }
  }
}
@media screen and (max-height: 500px) and (orientation: landscape) {
  .reset {
    .left_side {
      width: 100%;
    }
    .right_side {
      display: none;
    }
  }
}

.slide-err-enter-active,
.slide-err-leave-active {
  transition: all 0.3s ease-out;
}

.slide-err-enter-from,
.slide-err-leave-to {
  margin-top: -30px;
}
</style>
